import React from "react";
import CibilScoreComponent from "../Components/CIbilScoreText";
import LandingBG from "./../Images/landingNew.webp";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { landingContent } from "./landing";
import PaisaWapas from "./../Images/paiseWaps.webp";
import { useSelector } from "react-redux";
import FooterLinks from "./FooterLinks";
import CutomerStories from "../Components/CustomerStories/CustomerStories";
import ScoreTech from "../Components/ScoreTech/ScoreTech";
import Vector from "./../Images/Vector.png";

const { NODE_ENV } = process.env;
const isProd = NODE_ENV === "production";
const BASE_URL = process.env.REACT_APP_API_URL;

const highlightText = (text, wordsToHighlight) => {
  // Split the sentence by spaces
  const parts = text.split(" ");

  return parts?.map((part, index) => {
    if (wordsToHighlight?.includes(part)) {
      return (
        <span key={index} className="font-bold">
          {part}{" "}
        </span>
      );
    }
    return <span key={index}>{part} </span>;
  });
};

export const HomePage = () => {
  const { lang } = useParams();
  const currentContent = landingContent[lang];

  const handleProceed = () => {
    // Track the button click event in Google Analytics
    if (window.gtag) {
      window.gtag("event", "click", {
        event_category: "CTA",
        event_label: "Proceed Button Clicked",
        value: "1",
      });
    }
    try {
      if (window.fbq) {
        window.fbq("track", "Lead");
      }
    } catch (e) {
      console.log(e);
    }

    // Proceed to the login page
    window.open(`https://app.scorebadhao.com/user`, "_self");
  };

  if (!currentContent) {
    return <Navigate to="/en" />; // Redirect to '/en' if the route doesn't match
  }

  return (
    <div className="flex flex-col h-full gap-2 p-4 md:relative pb-14 bg-bdhao-clr md:mx-15pe md:mt-10 md:rounded-2xl md:bg-dark-bl-clr md:justify-center md:items-center ">
      <CibilScoreComponent heading={currentContent?.header} />
      <div
        className="relative flex justify-center md:mb-4"
        style={{
          background: `url(${Vector})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "270px",
        }}
      >
        <img
          className="px-12 py-2 md:w-50pe"
          style={{ minWidth: "130px", minHeight: "100px" }}
          src={LandingBG}
          alt="landing"
        />
        <img
          className="absolute right-0 bottom-45pn md:right-28"
          style={{ width: "130px", height: "130px" }}
          src={PaisaWapas}
        />
      </div>
      <div className="relative">
        <h2 className="p-2 leading-7 text-white po:w-1/2 font-700 font-22 md:text-center">
          {currentContent?.plan_title.map((segment, index) => {
            if (segment?.highlight) {
              return (
                <span key={index} className="text-customGold">
                  {segment?.highlight}
                </span>
              );
            }
            return <span key={index}>{segment.text}</span>;
          })}
        </h2>
      </div>

      <ul className="space-y-4">
        {currentContent?.features?.map((feature, index) => (
          <li
            key={index}
            className="flex items-center p-2 space-x-2 text-white rounded-lg bg-dark-bl-clr"
          >
            <div
              style={{ minWidth: "48px", height: "48px" }}
              className="flex items-center justify-center p-2 bg-black rounded-lg"
            >
              <img src={feature?.icon} />
            </div>
            <div>
              {/* <h3 className="text-lg font-semibold">{feature?.title}</h3> */}
              <p className="text-white text-14 font-400">
                {highlightText(feature?.description, feature?.wordsToHighlight)}{" "}
              </p>
            </div>
          </li>
        ))}
      </ul>
      <footer
        onClick={handleProceed}
        className="fixed flex items-center justify-center py-2 font-semibold cursor-pointer left-3 right-3 bottom-3 gradient-btn rounded-3xl text-17 md:left-12 md:right-12"
      >
        {currentContent?.cta}
      </footer>

      {/*<ScoreTech />*/}
      {/*<CutomerStories />*/}
      <FooterLinks />
    </div>
  );
};
