import React from "react";
import FirstIm from "./../../Images/bannerFirst.png";
import MidIm from "./../../Images/newBannerMain.png";
import LastIm from "./../../Images/BannerLast.png";

const data = [
  {
    imgUrl: FirstIm,
    id: "first"
  },
  {
    imgUrl: MidIm,
    id: "mid"
  },
  {
    imgUrl: LastIm,
    id: "last"
  }
];

function ScoreTech() {
  return (
    <div
      style={{
        background: " linear-gradient(152.67deg, #FFE49D 0%, #FED961 33.81%, #FEE180 46.74%, #FFE99D 82.54%, #EFCA47 99.45%)"
      }}
      className="w-full mt-4 rounded-2xl"
    >
      <div className="p-3 pb-0 text-black font-400 text-12">How Score Badhao Will look for you</div>
      <div className="p-3 pt-0 pb-2 font-bold text-black text-17">Track, Manage & Improve Score</div>
      {data && data.length > 0 ? (
        <>
          <div className={`flex overflow-auto hide-scroll-bar gap-3.5 p-4`}>
            {data?.map((value, vindex) => (
              <div style={{ minWidth: "240px" }} className="font-bold text-white rounded-2xl">
                <img src={value.imgUrl} alt="" style={{width:'240px',height:'340px'}} />
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ScoreTech;
